<template>
  <div class="container">
    <div class="body">
      <!-- <span v-html="noticeList.content"></span> -->
      <p style="text-indent: 0rem">尊敬的患者及家属：</p>
      <p>
        欢迎您来本院检查治疗，为了保证您在住院期间得到精心、妥善的治疗护理，增进医患双方理解，配合遵守医生各项规章制度，确保医疗安全，在您入院之际向您做一简要介绍，并请您协作好以下各项：
      </p>
      <p style="color: rgba(131, 133, 143, 1)">
        1、请按病房规定时间作息，室内请勿吸烟，听收音和录放机等请用二级。不得使用外接电源和电器。
      </p>
      <p style="color: rgba(131, 133, 143, 1)">
        2、住院患者饮食，由医师依病情决定，不能擅自更改。院外送进的食品，须经医生同意方可食用。
      </p>
      <p style="color: rgba(131, 133, 143, 1)">
        3、住院期间不能自行邀请院外医师诊治或自行用药。原则上不外购药品。如确需外购，需事先与主管医师协商，并经管理职能部门批准。
      </p>
      <p style="color: rgba(131, 133, 143, 1)">
        4、患者和家属不得向医院职工馈赠钱物。
      </p>
      <p style="color: rgba(131, 133, 143, 1)">
        5、住院患者未经许可不应进入诊疗场所、不得自行翻阅病案及其他医疗资料。
      </p>
      <p style="color: rgba(131, 133, 143, 1)">
        6、办理住院手续后，服从病房床位安排，患者不得擅自离院或外宿。擅自外出者，按自动出院处理，确有重要原因必须离院者，需签署离院知情承诺书，由此而产生的一切不良后果、责任自负。
      </p>
      <p style="color: rgba(131, 133, 143, 1)">
        7、住院患者可携带少量必须的生活卫生用品，其他物品不可带入病房，严禁带入重要文件、危险品等。钱财等贵重物品自行妥善保管。
      </p>
      <p style="color: rgba(131, 133, 143, 1)">
        8、请按医院规定时间和要求探视，是否陪伴须经主管医师、护士长根据病情决定，陪伴者需开“陪伴证”，持证出入医院。
      </p>
      <p style="color: rgba(131, 133, 143, 1)">
        9、爱护公共财务。勿将病房用物移出病房或供他人使用，损坏公物按价赔偿。不得在病房内使用酒精炉、电饭煲、电炉、电热被等，严禁向楼下倒水、扔污物、烟头，如因此发生火险，将追究法律责任。
      </p>
      <p style="color: rgba(131, 133, 143, 1)">
        10、住院期间如因治疗、手术用血，需提前按规定办理用血审批手续。
      </p>
      <p style="color: rgba(131, 133, 143, 1)">
        11、医院欢迎提供改进工作的意见。患者如有不遵守院规或违规者，医院要给予劝阻、教育，必要时通知工作单位或请有关部门处理。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "hospitalNotice",
  data() {
    return {
      noticeList: {},
    };
  },
  methods: {
    getData() {
      let postData = {
        parentId: this.$route.query.id,
      };
      this.$http.get("/api/content/infoByParentId", postData).then((res) => {
        this.noticeList = res.data.contents.find(
          (item) => item.title == "住院须知"
        );
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped>
.container {
  font-size: 0.4rem;
  text-align: left;
  margin-top: 0.2rem;
  width: 97%;
  margin: auto;
}
.container p {
  line-height: 0.8rem;
}
.container p:not(:nth-child(1)) {
  text-indent: 0.7rem;
}
.text-grey {
  color: rgba(131, 133, 143, 1);
}
/* .content img{
  width: 99%;
}
.content{
  font-size: 0.4rem;
  width: 97%;
  margin: auto;
  margin-top: 0.2rem;
  text-align: left;
} */
.body {
  width: 9rem;
  background-color: #fff;
  margin: 10px auto;
  padding: 10px;
  border-radius: 10px;
  -webkit-box-shadow: #e6e6e6 0px 0px 10px;
  -moz-box-shadow: #e6e6e6 0px 0px 10px;
  box-shadow: #e6e6e6 0px 0px 10px;
}
</style>
